.btn-whitespace{
    white-space: normal;
}
.speech {
    background-color: rgba(233, 236, 238, 1);
    color: black;
}
.speech::before {
    border-right-color: rgba(233, 236, 238, 1);
}

.speech .media-heading {
    color: black;
}

.speech-right > .speech {
    background-color: rgb(56, 160, 244);
    color: white;
}

.speech-right > .speech .media-heading {
    color: white;
}

.speech-right > .speech::before {
    border-left-color: rgb(56, 160, 244);
}

.form-row .parsley-error, #eurid .form-row .parsley-error {
    border-color: red;
}

.form-row .long-list, #eurid .form-row .long-list {
    max-width: 240px;
    display: block;
}

.form-row .error-tooltip span, #eurid .form-row .error-tooltip span {
    color: #fff;
    line-height: 20px;
    background: #f87961;
    border-radius: 4px;
    list-style: none;
    margin-left: 0;
    padding: 3px 3px 0 7px;
    padding-bottom: 3px;
    display: inline-block;
    margin-top: 3px;
    font-size: 11px;
    max-width: 100%;
}

.form-row .error-tooltip span:after, #eurid .form-row .error-tooltip span:after {
    content: "";
    position: relative;
    width: 0;
    height: 0;
    margin-left: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f87961;
    top: -23px;
    right: 78%;
}

.form-row .error-tooltip span span, #eurid .form-row .error-tooltip span span {
    font-size: 11px;
    margin-left: 7px;
}

.form-row .error-tooltip span.long-error, #eurid .form-row .error-tooltip span.long-error {
    margin-top: 0;
}

.form-row .error-tooltip span.long-error:after, #eurid .form-row .error-tooltip span.long-error:after {
    display: none;
}

.form-row .tab-pane .error-tooltip:after, #eurid .form-row .tab-pane .error-tooltip:after {
    left: -40%;
}

/* COOKIE BAR */
@media (min-width: 1200px) {
    #CybotCookiebotDialogBody {
        max-width: 992px !important;
    }
}

/* progress bar */
.progress .progress-bar {
    animation-name: animateBar;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}
@keyframes animateBar {
    0% {transform: translateX(-100%);}
    100% {transform: translateX(0);}
}

ul.request-soh-list {
    margin-bottom: 25px;
}

ul.request-soh-list li {
    margin-bottom: 8px;
}

form#login-form .long-list.parsley-errors-list {
    max-width: none;
    margin-bottom: 5px;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.row-eq-height > * {
    margin-bottom: 15px;
}

.row-eq-height > *[class^="col"] > .panel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-direction: column;
    justify-content: flex-start;
}

.row-eq-height > *[class^="col"] > .panel .panel-footer {
    margin: auto 0 0 0;
}

.row-eq-height > *[class^="col"] > .panel, .row-eq-height > *[class^="col"] > .panel-group{
    margin: 0;
    height: 100%;
}

.mnp-name {
    overflow-wrap: break-word;
}

.panel-footer .bootstrap-select {
    margin-bottom: 0;
}.CookieDeclaration a {
    color: #0391d1;
}

.CookieDeclaration a:hover {
    text-decoration: underline;
}

.alert-success .copy-clipboard-trigger-link {
    color: #fff;
    cursor: pointer;
}

.panel-body.well {
    margin-bottom: 0;
}

#dropdown-user .ic-user {
    font-size: 20px;
}

.navbar-top-links>li>a>i {
    font-size: 20px;
}
li#aside-toggle a i {
    font-size: 20px;
    vertical-align: -60%;
}

@media screen and (max-width: 760px) {
    #container > div > #aside-container, #container > div >  #aside-container > #aside , .aside-title{
        padding-top: 0px;
    }
}

#logout i {
    margin-top: 6px;
}

#page-alert:not(:empty){
    margin-top: 78px;
}

.navbar-brand {
    width: auto;
}

.brand-title {
    padding-left: 95px !important;
    width: auto !important;
}

.login-container .brand-title {
    padding-left: 5px !important;
}

#floating-top-right.floating-container {
    z-index: 1;
}

#eurid .cls-container #footer a {
    color: #69757b;
}

#eurid .cls-container #footer a.btn-link {
    color: #0391d1;
}

@media screen and (max-width: 760px) {
    .max-width-100-xs {
        max-width: 100% !important;
    }

    .pull-none-xs {
        float: none !important;
    }
}

#chat-button {
    display: block;
    color: #fff;
    border-radius: 30px !important;
    cursor: pointer;
    position: fixed;
    bottom: 45px;
    left: 15px;
    z-index: 999;
    opacity: 1;
    padding: 10px;
    font-size: 1.5em;
    box-shadow: none;
    /*transition: all 0.15s;*/
    width: auto;
    text-transform: none !important;
}

#chat-button span{
    display: inline-flex;
    transition: 0.5s width linear;
    width: 0;
    overflow: hidden;
}

#chat-button:hover span{
    width: 250px;
}

#eurid #feedback-form .panel-overlay{
    position: absolute;
}

.panel .panel-alert .alert-wrap.in {
    margin-top: 0;
}

.pricing .panel {
    margin-bottom: 0;
}

.enduserweb-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #20325f;
}

#footer.login-footer {
    position: relative;
}

.login-container {
    min-height: auto !important;
    padding-bottom: 0;
}

/*to avoid the flex taking it into account on the login page */
#djDebug {
    position: absolute
}

.d-none {
    display: none
}

#btn-language {
  background-color: transparent;
  color: #fff;
  border: solid 1px #fff;
  z-index: 999;
}

#language-modal ul.language-list {
  column-count: 2;
  padding: 0;
}

@media screen and (max-width: 768px) {
    #language-modal ul.language-list {
        column-count: 1;
    }
}



#language-modal ul.language-list li {
  list-style: none;
  padding: 0;
}

.text-white {
  color: #fff !important;
}

#language-modal a.language-option {
  color: #fff;
  background-color: rgb(32, 50, 95);
  width: 100%;
  margin: 2px;
  line-height: 3.2rem;
}

#language-modal a.language-option.selected,
#language-modal a.language-option:hover {
  color: #fff;
  background-color: #006591;
}

#language-modal a .two-letters-code {
  float: left;
  border: solid 1px #fff;
  width: 2em;
  height: 2em;
  margin-left: -0.4rem;
}