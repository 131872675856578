

#timeline-wrap {
    margin: 78px 0 20%;
    top: 100;
    position: relative;

}
#timeline {
    height: 1px;
    width: 100%;
    background-color: #aabbc4;
    position: relative;

}

.marker {
    z-index: 1000;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    background-color: #999999;
    border-radius: 50%;
}

.marker:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);

    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}


.timeline-icon-custom.one {
    background-color: #3e4f88 !important;
}

.timeline-icon-custom.two {
    background-color: #536295 !important;
}

.timeline-icon-custom.three {
    background-color: #6976a2 !important;
}

.timeline-icon-custom.four {
    background-color: #bfc7e1 !important;
}


.mfirst {
    top: -25px;
}

.m2 {
    top: -25px;
    left: calc(33% - 25px);
}

.m-middle {
    top: -25px;
    left: calc(50% - 25px);
}

.m3 {
    top: -25px;
    left: calc(66% - 25px);
}


.mlast {
    top: -25px;
    right: 0%
}

.timeline-panel {
    margin-top: 45px;
    width: 100%;
    position: relative;
    text-align: left;
    font-size: 13px;
    float: left;
    background-color: #fff;
    border-radius: 3px;
    padding: 0;
    /*margin-bottom: 45px;*/
}

.timeline-panel > .panel-heading:after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.timeline-panel:after {
    content: '';
    display: block;
    position: absolute;
    margin: -26px 0 0 26px;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom: 15px solid #fff;
}

.timeline-panel.middle-arrow:after {
    margin-left: calc(50% - 12px);
}

.timeline-panel.right-arrow:after {
    margin-left: calc(96% - 12px);
}

.timeline-panel.right-third-arrow:after {
    margin-left: calc(66% - 12px);
}

.marker-label {
    position: absolute;
    top: 30px;
    text-align: center;
    margin-left: -85px;
}